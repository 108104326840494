<template>
  <v-container grid-list-md>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <h2>{{ `${schedule.courseID} ${schedule.classID}` }}</h2>
        <span class="subheading">
          {{ `Period: ${schedule.startingDate} ~ ${schedule.endingDate},` }}
          {{ `Teacher: ${schedule.facultyID}` }}
        </span>
        <v-divider class="my-3" />
      </v-flex>
      <v-flex xs12>
        <v-tabs fixed-tabs>
          <v-tab
            v-for="(title, index) in tabs"
            :key="index"
          >
            {{ title }}
          </v-tab>
          <v-tab-item>
            <v-spacer />
            <enroll-status-panel :schedule="schedule" />
          </v-tab-item>
          <v-tab-item>
            <v-spacer />
            <pay-status-panel :schedule="schedule" />
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import EnrollStatusPanel from '@/components/course-schedule/EnrollStatusPanel.vue'
import PayStatusPanel from '@/components/course-schedule/PayStatusPanel.vue'

export default {
  name: 'CourseSchedule',
  components: {
    'enroll-status-panel': EnrollStatusPanel,
    'pay-status-panel': PayStatusPanel,
  },
  data() {
    return {
      schedule: {},
      tabs: [
        'Enroll Status',
        'Payment Status',
      ],
    }
  },
  created() {
    this.$store.dispatch('courseschedules/getScheduleById', this.$route.params.csID)
      .then((data) => { this.schedule = data })
  },
  methods: {
    deductFee() {
      this.$store.dispatch('transactions/createBySchedule', {
        csID: this.schedule.csID,
      }).then((data) => {
        console.log(data)
      })
    },
  },
}
</script>

<style>

</style>
