<template>
  <v-layout
    row
    wrap
  >
    <v-flex xs6>
      <v-card>
        <v-card-title>Enrolled Students</v-card-title>
        <v-list>
          <template v-for="(item, index) in enrolledStudents">
            <v-list-tile
              :key="item.id"
              :style="isSelected(item) ? 'background-color: #DCEDC8' : ''"
              avatar
              ripple
              @click="select(item)"
            >
              <v-list-tile-content>
                <v-list-tile-title>{{ `${printName(item)}` }}</v-list-tile-title>
                <v-list-tile-sub-title class="text--primary">
                  {{ `Grade ${item.grade}, ${item.attdType}, ${item.gender}, ${item.dateOfBirth}` }}
                </v-list-tile-sub-title>
                <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title>
              </v-list-tile-content>
              <v-flex xs1 />
              <v-list-tile-action>
                <v-list-tile-action-text>{{ item.id }}</v-list-tile-action-text>
              </v-list-tile-action>
              <v-list-tile-action>
                <v-list-tile-action-text>
                  <!-- <v-icon v-if="isPaid(item)" color="green">check_circle</v-icon> -->
                  <v-icon color="red">
                    info
                  </v-icon>
                </v-list-tile-action-text>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider
              v-if="index + 1 < enrolledStudents.length"
              :key="index"
            />
          </template>
        </v-list>
      </v-card>
    </v-flex>
    <v-flex xs6>
      <v-card>
        <v-card-title>
          Required Fees for {{ printName(selectedStudent) }}
          <v-spacer />
          <div v-if="isPaid(selectedStudent)">
            Paid in full
          </div>
          <v-btn
            v-else
            color="primary"
          >
            Incurr Founds
          </v-btn>
        </v-card-title>
        <v-list>
          <v-list-tile
            v-for="(item, index) in requiredFees"
            :key="index"
          >
            {{ item.type }}
            <v-spacer />
            <v-divider
              class="mx-3"
              inset
              vertical
            />
            <v-flex xs2>
              {{ `$${item.amount}` }}
            </v-flex>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import EventBus from '@/event-bus'

export default {
  name: 'PayStatusPanel',
  props: ['schedule'],
  data() {
    return {
      enrolledStudents: null,
      allStudents: null,
      onStudent: null,
      selectedStudent: null,
      studentFees: null,
      studentTransactions: null,
      studentBalances: null,
      studentCourses: null,
    }
  },
  computed: {
    requiredFees() {
      if (!this.studentFees) return []
      return this.studentFees.filter(fee => fee.deductMethod === 'Course')
    },
  },
  watch: {
    schedule(value) {
      this.$store.dispatch('students/getStudentsByScheduleID', value.csID)
        .then((data) => {
          const [first] = data
          this.enrolledStudents = data
          this.selectedStudent = first
        })

      this.$store.dispatch('studentcourses/getStudentCourseByCsID', value.csID)
        .then((data) => {
          this.studentCourses = data
        })
    },
    selectedStudent(value) {
      if (!value) return
      this.$store.dispatch('fees/getFeesByStudentId', value.id)
        .then((data) => {
          this.studentFees = data
        })
      this.$store.dispatch('transactions/getAvailableTransactionByStudentId', value.id)
        .then((data) => {
          this.studentTransactions = data
        })
      this.$store.dispatch('fees/getStudentBalanceFeesByDate', { id: value.id, date: this.schedule.startingDate })
        .then((data) => {
          this.studentBalances = data
        })
    },
  },
  created() {
    EventBus.$on('studentEnrolled', student => this.enrolledStudents.push(student))
    EventBus.$on(
      'studentDisenrolled',
      (student) => {
        this.enrolledStudents
          .splice(this.enrolledStudents.findIndex(item => item.id === student.id), 1)
      },
    )
    this.$store.dispatch('students/getStudentsByScheduleID', this.schedule.csID)
      .then((data) => {
        const [first] = data
        this.enrolledStudents = data
        this.selectedStudent = first
      })
  },
  beforeDestroy() {
    EventBus.$off('studentEnrolled')
    EventBus.$off('studentDisenrolled')
  },
  methods: {
    select(item) {
      this.selectedStudent = item
    },
    printName(student) {
      return student ? `${this.capFirst(student.firstName)} ${this.capFirst(student.lastName)}(${this.capFirst(student.perferName)})` : ''
    },
    capFirst(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
    },
    isSelected(item) {
      if (!item) return false
      return item === this.selectedStudent
    },
    menuItemClick(action, student) {
      switch (action) {
        case 'Enroll':
          this.enrollStudentToClass(student)
          break
        case 'Cancel':
          this.disenrollStudent(student)
          break
        default:
      }
    },
    getFeeBalance(feeID) {
      if (!feeID || !this.studentTransactions) return 0
      return this.studentTransactions
        .filter(transaction => transaction.feeID === feeID)
        .map(transaction => Number(transaction.amount))
        .reduce((accu, curr) => accu + curr, 0)
    },
    isPaid(student) {
      if (!student || !this.studentCourses) return false
      return this.studentCourses.find(item => item.studID === student.id).payStatus === 'paid'
    },
  },
}
</script>

<style>
</style>
