<template>
  <v-layout
    row
    wrap
  >
    <v-flex xs6>
      <v-card>
        <v-card-title>Enrolled Students</v-card-title>
        <v-list>
          <template v-for="(item, index) in enrolledStudents">
            <v-list-tile
              :key="item.id"
              :style="onStudent === item ? 'background-color: #DCEDC8' : ''"
              avatar
              ripple
              @click="select(item)"
              @mouseover="onStudent = item"
            >
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ `${printName(item.firstName, item.lastName, item.perferName)}` }}
                </v-list-tile-title>
                <v-list-tile-sub-title
                  class="text--primary"
                >
                  {{ `Grade ${item.grade}, ${item.attdType}, ${item.gender}, ${item.dateOfBirth}` }}
                </v-list-tile-sub-title>
                <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title>
              </v-list-tile-content>
              <v-flex sm1 />
              <v-list-tile-action>
                <v-list-tile-action-text>{{ item.id }}</v-list-tile-action-text>
              </v-list-tile-action>
              <v-list-tile-action>
                <v-menu
                  v-if="onStudent === item"
                  bottom
                  left
                >
                  <v-btn
                    slot="activator"
                    icon
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>

                  <v-list>
                    <v-list-tile
                      v-for="(menuItem, i) in menuItems"
                      :key="i"
                      @click="menuItemClick(menuItem.title, item)"
                    >
                      <v-list-tile-title>{{ menuItem.title }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider
              v-if="index + 1 < enrolledStudents.length"
              :key="index"
            />
          </template>
        </v-list>
      </v-card>
    </v-flex>
    <v-flex xs6>
      <v-card>
        <v-card-title>
          Avaliable Students
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-list>
          <template v-for="(item, index) in searchedStudents">
            <v-list-tile
              :key="item.id"
              :style="onStudent === item ? 'background-color: #DCEDC8' : ''"
              avatar
              ripple
              @click="select(item)"
              @mouseover="onStudent = item"
            >
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ `${printName(item.firstName, item.lastName, item.perferName)}` }}
                </v-list-tile-title>
                <v-list-tile-sub-title
                  class="text--primary"
                >
                  {{ `Grade ${item.grade}, ${item.attdType}, ${item.gender}, ${item.dateOfBirth}` }}
                </v-list-tile-sub-title>
                <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-list-tile-action-text>{{ item.id }}</v-list-tile-action-text>
              </v-list-tile-action>
              <v-list-tile-action>
                <v-menu
                  v-if="onStudent === item"
                  bottom
                  left
                >
                  <v-btn
                    slot="activator"
                    icon
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                  <v-list>
                    <v-list-tile
                      v-for="(menuItem, i) in [{title: 'Enroll'}]"
                      :key="i"
                      @click="menuItemClick(menuItem.title, item)"
                    >
                      <v-list-tile-title>{{ menuItem.title }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider
              v-if="index + 1 < unenrolledStudents.length"
              :key="index"
            />
          </template>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import EventBus from '@/event-bus'

export default {
  name: 'EnrollStatusPanel',
  props: ['schedule'],
  data() {
    return {
      enrolledStudents: [],
      allStudents: [],
      onStudent: null,
      selected: {},
      search: '',
    }
  },
  computed: {
    unenrolledStudents() {
      return this.allStudents
        .filter(student => !this.enrolledStudents.some(item => student.id === item.id))
    },
    searchedStudents() {
      return this.unenrolledStudents
        .filter(({ id, firstName, lastName }) => Object.values({ id, firstName, lastName })
          .some(value => value.includes(this.search)))
    },
    menuItems() {
      return [
        {
          title: 'Cancel',
        },
        {
          title: 'Withdrow',
        },
        {
          title: 'Drop',
        },
      ]
    },
  },
  watch: {
    schedule(value) {
      this.$store.dispatch('students/getStudentsByScheduleID', value.csID)
        .then((data) => { this.enrolledStudents = data })
    },
  },
  created() {
    this.$store.dispatch('students/getStudentsByScheduleID', this.schedule.csID)
      .then((data) => { this.enrolledStudents = data })

    this.$store.dispatch('students/all')
      .then((data) => { this.allStudents = data })
  },
  methods: {
    select(item) {
      this.selected = item
    },
    printName(first, last, prefer) {
      return `${this.capFirst(first)} ${this.capFirst(last)}(${this.capFirst(prefer)})`
    },
    capFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    isSelected(item) {
      return item === this.selected
    },
    menuItemClick(action, student) {
      switch (action) {
        case 'Enroll':
          this.enrollStudentToClass(student)
          break
        case 'Cancel':
          this.disenrollStudent(student)
          break
        default:
      }
    },
    enrollStudentToClass(student) {
      this.$store.dispatch('studentcourses/create', {
        studID: student.id,
        csID: this.schedule.csID,
      })
        .then(() => {
          this.enrolledStudents.push(student)
          // this.$emit('newStudentEnrolled', this.selected)
          EventBus.$emit('studentEnrolled', student)
        })
    },
    disenrollStudent(student) {
      this.$store.dispatch('studentcourses/delete', {
        studID: student.id,
        csID: this.schedule.csID,
      }).then(() => {
        this.enrolledStudents.splice(this.enrolledStudents
          .findIndex(item => item.id === this.selected.id), 1)
        EventBus.$emit('studentDisenrolled', this.selected)
      })
    },
  },
}
</script>

<style>
</style>
